import React, { useEffect, useState } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import * as Tabs from '@radix-ui/react-tabs';
import "../css/radixdialog.css";
import { LsIcons } from './ui/LsIcons';
import APITemplates, { getAPITemplates } from './hooks/APITemplates';

const MAX_SEED = 4294967294;

const SettingsDialog = ({ open, onOpenChange, settings, setSettings, promptRefinerSettings, setPromptRefinerSettings, initialTab = 'text2image' }) => {
  const [models, setModels] = useState([]);
  const [isLoadingModels, setIsLoadingModels] = useState(false);
  const [fetchModelsError, setFetchModelsError] = useState(null);
  const [localSettings, setLocalSettings] = useState(settings);
  const [localPromptRefinerSettings, setLocalPromptRefinerSettings] = useState(promptRefinerSettings);
  const [isStabilityAI, setIsStabilityAI] = useState(localSettings.url.includes('stability'));
  const [currentTab, setCurrentTab] = useState(initialTab);

  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    const loadTemplates = async () => {
      const loadedTemplates = await getAPITemplates();
      setTemplates(loadedTemplates);
    };
    loadTemplates();
  }, []);

  useEffect(() => {
    const savedText2imageSettings = JSON.parse(localStorage.getItem('text2image-settings')) || settings;
    const savedPromptRefinerSettings = JSON.parse(localStorage.getItem('prompt-refiner-settings')) || promptRefinerSettings;

    setLocalSettings(savedText2imageSettings);
    setLocalPromptRefinerSettings(savedPromptRefinerSettings);
  }, [settings, promptRefinerSettings]);

  useEffect(() => {
    setIsStabilityAI(localSettings.url.includes('api.stability.ai'));
    // console.log("setIsStabilityAI", localSettings.url.includes('api.stability.ai'))
  }, [localSettings.url]);

  useEffect(() => {
    setCurrentTab(initialTab);
    // console.log("useEffect setCurrentTab", initialTab)
  }, [initialTab]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log("handleChange: ", name, value);

    setLocalSettings(prevSettings => {
      const updatedSettings = { ...prevSettings, [name]: value };

      // 如果 url 发生变化，且新的 url 是 Stability AI，则重置 aspect_ratio 否则重置 image_size
      if (name === 'url') {
        if (value.includes('api.stability.ai')) {
          updatedSettings.aspect_ratio = '1:1';
        } else if (value === 'fal-ai/flux-pro/v1.1') {
          updatedSettings.image_size = 'square_hd';
        } else {
          updatedSettings.image_size = '1024x1024';
        }
      }

      return updatedSettings;
    });
    // setSettings(localSettings);
  };

  const handleChangeProvider = (e) => {
    const { name, value } = e.target;
    // console.log("handleChangeProvider: ", name, value);

    setLocalPromptRefinerSettings(prevSettings => {
      let updatedSettings = { ...prevSettings, [name]: value };

      if (name === 'provider') {
        const selectedTemplate = templates.find(template => template.name === value);
        if (selectedTemplate) {
          updatedSettings = {
            ...updatedSettings,
            provider: value,
            base_url: decodeURIComponent(selectedTemplate.url.split('?baseurl=')[1].split('&')[0]),
            apiKey: value === 'ollama' ? 'ollama' : prevSettings.apiKey
          };
        }
      }

      // 如果 provider, API Key 或 base_url 发生变化，自动获取可用模型
      if (name === 'provider' || name === 'apiKey' || name === 'base_url') {
        setTimeout(() => fetchModels(updatedSettings), 0);
      }

      return updatedSettings;
    });
  };

  const handleSaveSettings = () => {
    onOpenChange(false);
    setSettings(localSettings);
    setPromptRefinerSettings(localPromptRefinerSettings);

    localStorage.setItem('text2image-settings', JSON.stringify(localSettings));
    localStorage.setItem('prompt-refiner-settings', JSON.stringify(localPromptRefinerSettings));
  };

  const handleGetApiKey = () => {
    if (currentTab === 'text2image') {
      let apiKeyUrl;
      if (localSettings.url.includes('api.stability.ai')) {
        apiKeyUrl = 'https://platform.stability.ai/account/keys';
      } else if (localSettings.url.includes('siliconflow.cn')) {
        apiKeyUrl = 'https://cloud.siliconflow.cn/account/ak';
      } else if (localSettings.url === 'fal-ai/flux-pro/v1.1') {
        apiKeyUrl = 'https://fal.ai/dashboard/keys';
      } else {
        apiKeyUrl = 'https://example.com/get-api-key'; // 默认链接，可以根据需要修改
      }
      window.open(apiKeyUrl, '_blank');
    } else {
      const template = templates.find(t => t.name === localPromptRefinerSettings.provider);
      const apiKeyUrl = template ? decodeURIComponent(template.url.split('&apiKeyUrl=')[1].split('&')[0]) : 'https://example.com/get-api-key';
      window.open(apiKeyUrl, '_blank');
    }
  };

  const generateRandomSeed = () => {
    const randomSeed = Math.floor(Math.random() * MAX_SEED);
    setLocalSettings(prevSettings => ({
      ...prevSettings,
      seed: randomSeed
    }));
  };

  const fetchModels = async (settings = localPromptRefinerSettings) => {
    setIsLoadingModels(true);
    setFetchModelsError(null);
    const { base_url, apiKey, provider } = settings;
    const isOllama = provider === 'ollama' ? '1' : '0';

    try {
      let url;
      if (isOllama === '1') {
        const { protocol, host } = new URL(base_url);
        url = `${protocol}//${host}/api/tags`;
      } else {
        url = `${base_url}/models`;
      }

      const response = await fetch(url, {
        headers: {
          'Authorization': `Bearer ${apiKey}`
        }
      });

      if (!response.ok) {
        const text = await response.text();
        console.error(`HTTP error! status: ${response.status}, text: ${text}`);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const contentType = response.headers.get("content-type");
      if (!contentType || !contentType.includes("application/json")) {
        console.error("Invalid content type, expected application/json");
        throw new Error("Invalid content type, expected application/json");
      }

      const data = await response.json();
      if (isOllama === '1') {
        setModels(data.models.map(model => ({ value: model.name.split(':')[0], label: model.name.split(':')[0] })));
      } else {
        setModels(data.data.map(model => ({ value: model.id, label: model.id })));
      }

    } catch (error) {
      setFetchModelsError("Error fetching models check your apikey: " + error.message);
      setModels([]);
    } finally {
      setIsLoadingModels(false);
    }
  };

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Overlay className="DialogOverlay" />
      <Dialog.Content className="DialogContent">
        <Dialog.Title className="DialogTitle">Advanced Settings</Dialog.Title>
        <Tabs.Root className="TabsRoot" value={currentTab} onValueChange={setCurrentTab}>
          <Tabs.List className="TabsList">
            <Tabs.Trigger className="TabsTrigger" value="text2image">Text2Image</Tabs.Trigger>
            <Tabs.Trigger className="TabsTrigger" value="promptRefiner">Prompt Refiner</Tabs.Trigger>
          </Tabs.List>
          <Tabs.Content className="TabsContentDialog" value="text2image">
            <form>
              <div className="Fieldset">
                <label htmlFor="url" className="Label">API Endpoint</label>
                <select
                  id="url"
                  name="url"
                  value={localSettings.url}
                  onChange={handleChange}
                  className="Input"
                >
                  <option value="fal-ai/flux-pro/v1.1">
    FAL Flux 1.1 PRO
  </option>
                  <option value="https://api.stability.ai/v2beta/stable-image/generate/sd3">
                    Stability AI
                  </option>
                  <option value="https://api.siliconflow.cn/v1/stabilityai/stable-diffusion-3-medium/text-to-image">
                    Siliconflow API
                  </option>
                </select>
              </div>
              {isStabilityAI ? (
  <div className="Fieldset">
    <label htmlFor="aspect_ratio" className="Label">Aspect Ratio</label>
    <select
      id="aspect_ratio"
      name="aspect_ratio"
      value={localSettings.aspect_ratio}
      onChange={handleChange}
      className="Input"
    >
      <option value="1:1">1:1</option>
      <option value="16:9">16:9</option>
      <option value="21:9">21:9</option>
      <option value="2:3">2:3</option>
      <option value="3:2">3:2</option>
      <option value="4:5">4:5</option>
      <option value="5:4">5:4</option>
      <option value="9:16">9:16</option>
      <option value="9:21">9:21</option>
    </select>
  </div>
) : (
  <div className="Fieldset">
    <label htmlFor="image_size" className="Label">Image Size</label>
    <select
      id="image_size"
      name="image_size"
      value={localSettings.image_size}
      onChange={handleChange}
      className="Input"
    >
      {localSettings.url === 'fal-ai/flux-pro/v1.1' ? (
        <>
          <option value="square_hd">Square HD</option>
          <option value="square">Square</option>
          <option value="portrait_4_3">Portrait 4:3</option>
          <option value="portrait_16_9">Portrait 16:9</option>
          <option value="landscape_4_3">Landscape 4:3</option>
          <option value="landscape_16_9">Landscape 16:9</option>
        </>
      ) : (
        <>
          <option value="1024x1024">1024x1024</option>
          <option value="1536x1024">1536x1024</option>
          <option value="1536x2048">1536x2048</option>
        </>
      )}
    </select>
  </div>
)}
              <div className="Fieldset">
                <label htmlFor="apikey" className="Label">API Key</label>
                <input
                  id="apikey"
                  type="text"
                  name="apikey"
                  placeholder="Enter API key here"
                  value={localSettings.apikey}
                  onChange={handleChange}
                  className="Input"
                />
              </div>
              <div className="Fieldset">
                <label htmlFor="num_inference_steps" className="Label">Inference Steps</label>
                <input
                  id="num_inference_steps"
                  type="number"
                  name="num_inference_steps"
                  value={localSettings.num_inference_steps}
                  onChange={handleChange}
                  className="Input"
                />
              </div>
              <div className="Fieldset">
                <label htmlFor="guidance_scale" className="Label">CFG</label>
                <input
                  id="guidance_scale"
                  type="number"
                  step="0.1"
                  name="guidance_scale"
                  value={localSettings.guidance_scale}
                  onChange={handleChange}
                  className="Input"
                />
              </div>
              <div className="Fieldset">
                <label htmlFor="negative_prompt" className="Label">Negative Prompt</label>
                <input
                  id="negative_prompt"
                  type="text"
                  name="negative_prompt"
                  value={localSettings.negative_prompt}
                  onChange={handleChange}
                  className="Input"
                />
              </div>
              <div className="Fieldset relative">
                <label htmlFor="seed" className="Label">Seed</label>
                <input
                  id="seed"
                  type="text"
                  name="seed"
                  value={localSettings.seed || ''}
                  onChange={handleChange}
                  className="Input"
                />
                {/*<button*/}
                {/*  type="button"*/}
                {/*  className="absolute right-2 top-6"*/}
                {/*  onClick={generateRandomSeed}*/}
                {/*>*/}
                {/*  {LsIcons.Text2Image_seed_svg_icon}*/}
                {/*</button>*/}
              </div>
            </form>
          </Tabs.Content>
          <Tabs.Content className="TabsContentDialog" value="promptRefiner">
            <form>
              <div className="Fieldset">
                <label htmlFor="provider" className="Label">Provider</label>
                <select
                  id="provider"
                  name="provider"
                  value={localPromptRefinerSettings.provider}
                  onChange={handleChangeProvider}
                  className="Input"
                >
                  {templates.map(template => (
                    <option key={template.name} value={template.name}>
                      {template.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="Fieldset">
                <label htmlFor="base_url" className="Label">Base URL</label>
                <input
                  id="base_url"
                  type="text"
                  name="base_url"
                  placeholder="Enter base URL here"
                  value={localPromptRefinerSettings.base_url}
                  onChange={handleChangeProvider}
                  className="Input"
                />
              </div>
              <div className="Fieldset">
                <label htmlFor="apiKey" className="Label">API Key</label>
                <input
                  id="apiKey"
                  type="text"
                  name="apiKey"
                  placeholder="Enter API key here"
                  value={localPromptRefinerSettings.apiKey}
                  onChange={handleChangeProvider}
                  className="Input"
                />
              </div>
              <div className="Fieldset">
                <label htmlFor="model" className="Label">Model</label>
                <input
                  id="model"
                  type="text"
                  name="model"
                  placeholder="Enter model here"
                  value={localPromptRefinerSettings.model}
                  onChange={handleChangeProvider}
                  className="Input"
                />
              </div>
              <div className="Fieldset relative">
                <label htmlFor="available_models" className="Label">Available Models</label>
                <select
                  id="available_models"
                  name="available_models"
                  value={localPromptRefinerSettings.available_models}
                  onChange={(e) => {
                    handleChangeProvider(e);
                    setLocalPromptRefinerSettings(prevSettings => ({
                      ...prevSettings,
                      model: e.target.value
                    }));
                  }}
                  className="Input"
                >
                  {models.map(model => (
                    <option key={model.value} value={model.value}>
                      {model.label}
                    </option>
                  ))}
                </select>
                <button type="button" title="fetch models" onClick={fetchModels} className="Button absolute right-4 top-1">
                  {LsIcons.Text2Image_fetach_llm_models}
                </button>
                {/* {isLoadingModels && <div>Loading models...</div>}
                {fetchModelsError && <div>{fetchModelsError}</div>} */}
              </div>
              {fetchModelsError && <div>{fetchModelsError}</div>}
            </form>
          </Tabs.Content>
        </Tabs.Root>
        <div className="flex justify-end mt-4 gap-4">
          <Dialog.Close asChild>
            <button className="Button green" onClick={handleSaveSettings}>
              Save Changes
            </button>
          </Dialog.Close>
          <button
            type="button"
            className="Button violet"
            onClick={handleGetApiKey}
          >
            Get API Key
          </button>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default SettingsDialog;
